/**
* Desktop styles
*/
.main-navigation-vertical-menu__desktop-wrapper {
    max-width: 800px;
}

/**
* Common styles
*/
.main-navigation-vertical-menu__main-navigation-link {
    a,
    span {
        font-weight: var(--fw-bold);
    }

    svg {
        transform: scale(0.9) rotate(0deg);
    }
}

.main-navigation-vertical-menu__main-navigation-link-active {
    height: 42px;
}

.main-navigation-vertical-menu__navigation-list-item {
    &--headline {
        a,
        span {
            font-weight: var(--fw-bold) !important; /* stylelint-disable-line declaration-no-important */
        }
    }

    a,
    span {
        font-weight: var(--fw-medium);
    }
}
