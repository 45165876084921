@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Thin.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 100 100;
}
@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Thinitalic.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 100 100;
}

@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Light.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 200 300;
}
@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Lightitalic.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 200 300;
}

@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Regular.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 400 600;
}
@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Regularitalic.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 400 600;
}

@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Bold.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 700 800;
}
@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Bolditalic.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 700 800;
}

@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Black.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 900 1000;
}
@font-face {
    font-family: 'Earl Sans';
    src: url('../assets/fonts/EarlSans-Blackitalic.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 900 1000;
}
