// Custom Properties in tupperware-nl
:root {
    // Colors
    --theme-primary: #1eb5dd;
    --theme-secondary: #dcf3fa;
    --theme-tertiary: #f5f5f5;

    --theme-primary-transparent: #1eb5dd00;
    --theme-primary-opaque: rgb(123, 214, 220, 0.05);

    --theme-attention: #1eb5dd;
    --theme-promotion: #1eb5dd;
    --theme-beige: #dcf3fa;
    --theme-cta: #1eb5dd;
    --theme-cta-font-color: #fff;

    --menu-color-primary: #222;

    // Fonts
    --ff-base: 'Earl Sans', sans-serif;
    --ff-title: 'Earl Sans', sans-serif;
    --ff-headline: 'Earl Sans', sans-serif;
    --font-base: 'Earl Sans', sans-serif;
    --font-title: 'Earl Sans', sans-serif;
    --font-headlines: 'Earl Sans', sans-serif;
    --ff-menu: 'Earl Sans', sans-serif;
    --font-product-price: 'Earl Sans', sans-serif;
    --fs-badge-small: 12px;

    --fs-main-navigation-desktop: 16px;
    --fs-main-navigation-mobile: 18px;
    --fs-sub-navigation-desktop: 16px;

    // AccordionPDP
    --fs-accordionpdp-title: 18px;

    // Footer
    --fs-footer-header-desktop: 22px;

    // Mobile Menu
    --mobile-menu-top: 110px;

    // Desktop Navigation
    --desktop-navigation-font-weight: var(--fw-bold);
}
