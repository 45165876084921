.main-navigation-vertical-brand__logo-container {
    img {
        min-width: 150px;
        height: auto;

        @include mq($from: desktop) {
            min-width: 190px;
        }
    }
}

.main-navigation-vertical-brand__icon-wrapper {
    width: 75px;

    .country-switch {
        display: none;
    }
}

.main-navigation-vertical-brand__icon {
    margin-right: 5px;
}
